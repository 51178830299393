import React, { useState, useContext } from 'react';
import { Button, Grid, Header, Message, Segment, Icon, Form, Table, Dimmer, Loader } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../userContext';

const GET_RIDE = gql`
    query GetRide($userId: uuid!) {
        rideshare(where: {userId: {_eq: $userId}}) {
            id
            userId
            departFrom
            departFromDate
            departFromTime
            spots
            shareMail
            sharePhone
        }
    }  
`;

const RideSharePostRidePage = () => {
    const userContext = useContext(UserContext);
    const { loading, error, data, refetch } = useQuery(GET_RIDE, {
        variables: {
            userId: userContext.user.id
        },
        fetchPolicy: "network-only"
    });

    const [rideState, setRideState] = useState({
        departFrom: '',
        departFromDate: '',
        departFromTime: '',
    });

    const handleRideChange = (e, data) => {
        setRideState({
            ...rideState,
            [data.name]: data.value === undefined ? data.checked : data.value
        })
    }

    const arrivingRideOptions = [
        { key: '2023-05-11', value: '2023-05-11', text: 'Thu 11 May (Building Crew)' },
        { key: '2023-05-12', value: '2023-05-12', text: 'Fri 12 May (Building Crew)' },
        { key: '2023-05-13', value: '2023-05-13', text: 'Sat 13 May (Building Crew)' },
        { key: '2023-05-14', value: '2023-05-14', text: 'Sun 14 May (Building Crew)' },
        { key: '2023-05-15', value: '2023-05-15', text: 'Mon 15 May (Building Crew)' },
        { key: '2023-05-16', value: '2023-05-16', text: 'Tue 16 May (Building Crew)' },
        { key: '2023-05-17', value: '2023-05-17', text: 'Wed 17 May' },
        { key: '2023-05-18', value: '2023-05-18', text: 'Thu 18 May' },
        { key: '2023-05-19', value: '2023-05-19', text: 'Fri 19 May' },
        { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' }
    ]

    const departingRideOptions = [
        { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' },
        { key: '2023-05-21', value: '2023-05-21', text: 'Sun 21 May' },
        { key: '2023-05-22', value: '2023-05-22', text: 'Mon 22 May' },
        { key: '2023-05-23', value: '2023-05-23', text: 'Tue 23 May (Breakdown Crew)' },
        { key: '2023-05-24', value: '2023-05-24', text: 'Wed 24 May (Breakdown Crew)' },
        { key: '2023-05-25', value: '2023-05-25', text: 'Thu 25 May (Breakdown Crew)' }
    ]

    const timeOfDayOptions = [
        { key: 'morning', value: 'morning', text: 'In the morning' },
        { key: 'afternoon', value: 'afternoon', text: 'In the afternoon' },
        { key: 'evening', value: 'evening', text: 'In the evening' },
        { key: 'night', value: 'night', text: 'In the night' },
    ]

    const spotsAvailableOptions = [
        { key: 1, value: 1, text: 1 },
        { key: 2, value: 2, text: 2 },
        { key: 3, value: 3, text: 3 },
        { key: 4, value: 4, text: 4 },
    ]

    if (loading) {
        return (
            <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Message attached style={{ position: 'relative' }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Rideshare
                        </Header>
                    </Message>
                    <Segment attached style={{ height: 100 }}>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                </Grid.Column>
            </Grid >
        )
    }

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Rideshare
                    </Header>
                    <Link as={Link} to={'/rideshare'} title='Back to rideshare overview'>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='car' />
                        </Button>
                    </Link>
                </Message>
                {/* <Segment attached> */}
                <div>
                    {data?.rideshare.length > 0 ? (
                        <Segment attached>
                            <p>
                                <Icon name='car' size='huge' />
                                <br /><br />
                                You already posted a ride! Click here to remove it
                            </p>
                        </Segment>
                    ) : (<>
                        <Form className='attached fluid segment'>
                            <Header as='h3' textAlign='center'>
                                Post your ride to find passengers!
                            </Header>
                            <p>
                                Going to Girtenmuehle. Please tell your departure location and time
                            </p>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    label='Departing from'
                                    name='departFrom'
                                    value={rideState.departFrom}
                                    onChange={handleRideChange}
                                    fluid
                                    //icon='phone'
                                    //iconPosition='left'
                                    placeholder='Amsterdam'
                                />
                                <Form.Select
                                    label='Spots Available'
                                    name='seatsAvailable'
                                    value={rideState.seatsAvailable}
                                    onChange={handleRideChange}
                                    placeholder='Spots available'
                                    options={spotsAvailableOptions}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    label='Date'
                                    name='departFromDate'
                                    value={rideState.departFromDate}
                                    onChange={handleRideChange}
                                    placeholder='Date'
                                    options={arrivingRideOptions}
                                />
                                <Form.Select
                                    label='Time'
                                    name='departFromTime'
                                    value={rideState.departFromTime}
                                    onChange={handleRideChange}
                                    placeholder='Time'
                                    options={timeOfDayOptions}
                                />
                            </Form.Group>
                            {/* <p>
                                Going back home. When are you leaving the Girtenmuehle and what is your destination
                            </p>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    label='Leaving from'
                                    name='leavingFrom'
                                    value={rideState.preferredBuddy}
                                    onChange={handleRideChange}
                                    fluid
                                    //icon='phone'
                                    //iconPosition='left'
                                    placeholder='Name of your preferred buddy'
                                />
                                <Form.Select
                                    label='Departure Date'
                                    name='departureDate'
                                    value={rideState.departureDate}
                                    onChange={handleRideChange}
                                    placeholder='Departure Date'
                                    options={departingRideOptions}
                                />
                                <Form.Select
                                    label='Departure Time'
                                    name='departureTime'
                                    value={rideState.departureTime}
                                    onChange={handleRideChange}
                                    placeholder='Departure Time Of Day'
                                    options={timeOfDayOptions}
                                />
                            </Form.Group> */}
                            <p>
                                Be sure to keep this information up to date! <br /> <br /> Which contact details do you want to share in order for people to reach you?
                            </p>
                            <Form.Group widths='equal'>
                                <Form.Checkbox
                                    label='Share my phonenumber'
                                    name='sharePhone'
                                    key='sharePhone'
                                    checked={rideState.sharePhone}
                                    onChange={handleRideChange}
                                />
                                <Form.Checkbox
                                    label='Share my email'
                                    name='shareEmail'
                                    key='shareEmail'
                                    checked={rideState.shareEmail}
                                    onChange={handleRideChange}
                                />
                            </Form.Group>
                            <Button
                                type='button'
                                color='teal'
                                size='large'
                                icon='car'
                                labelPosition='left'
                                content='Post Ride'
                                onClick={() => alert('posted!')} />
                            <br /><br />
                        </Form>
                    </>)}
                </div>
                {/* </Segment> */}
                <Message attached='bottom' info>
                    Back to <Link to='/rideshare'>available rides</Link>
                </Message>
            </Grid.Column>
        </Grid>
    )
}

export default RideSharePostRidePage