import React, { } from 'react';
import { Button, Grid, Header, Message, Segment, Icon, Accordion } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const panels = [
    {
        key: 'minimum-age',
        title: 'What is the age limit?',
        content: [
            'To visit our festival you need to be at least 18 years old. No children, sorry!'
        ].join(' '),
    },
    {
        key: 'bring-your-own-food-and-drinks',
        title: 'Is it allowed to bring your own food and drinks?',
        content: {
            content: (
                <div>
                    <p>
                        While we do sell organic food and drinks on the festival, you are allowed
                        to bring your own food as well.
                    </p>
                    <p>
                        Please know that open fires on the camping are not allowed and what other
                        text should we put here @Vincent @Gidi?
                    </p>
                </div>
            ),
        },
    },
]

const FaqPage = () => {
    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Frequently Asked Questions
                    </Header>
                    <Link as={Link} to={'/home'} title='Back home'>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='home' />
                        </Button>
                    </Link>
                </Message>
                <Segment attached textAlign='left'>
                    <Accordion panels={panels} />
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default FaqPage