import React, { createContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ReactGA from 'react-ga';

const USER_QUERY = gql`
    query GetUser($email: String!) {
        user(where: {email: {_eq: $email}}) {
            id
            firstName
            lastName
            email
            phone
            events {
              eventId
              phase
              event {
                eventDate
                eventName
              }
            }
            acts {
              eventId
            }
            lastLogin
            userType
        }
    }
`;

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        events: [],
        lastLogin: undefined
    });
    //const [location, setLocation] = useState("Mars");

    // eslint-disable-next-line
    const { loading, error, data, refetch } = useQuery(USER_QUERY, {
        variables: { email: localStorage.getItem('email')},
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data) {
            setUser({
                ...user,
                ...data.user[0]
            })
            ReactGA.set({
                user: user.id,
                // any data that is relevant to the user session
                // that you would like to track with google analytics
              })
        }

    }, [data])

    return (
        <UserContext.Provider
            value={{
                user,
                //location,
                setUser,
                //setLocation
                refetch
            }}
        >
            {children}
        </UserContext.Provider>
    );
};