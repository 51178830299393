import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, Modal, Table, Grid, List, Header, Message, Icon, Segment } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { UserContext } from '../userContext';

const EVENT_QUERY = gql`
    query GetEvent {
        event {
            id
            eventName
            eventDate
      }
    }
`;

const DELETE_USER_EVENT_QUERY = gql`
    mutation DeleteUserEvent($userId: uuid!, $eventId: uuid!) {
        delete_user_event(where: {userId: {_eq: $userId}, eventId: {_eq: $eventId}}) {
            affected_rows
        }
    }
`;

const DELETE_USER_ACT_QUERY = gql`
    mutation DeleteUserAct($userId: uuid!, $eventId: uuid!) {
        delete_user_act(where: {userId: {_eq: $userId}, eventId: {_eq: $eventId}}) {
            affected_rows
        }
    }
`;

const EventList = (props) => {
    const [phase, setPhase] = useState(-1);
    const [eventData, setEventData] = useState([])
    const { loading, error, data, refetch } = useQuery(EVENT_QUERY, {
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data) {
            setEventData(data.event)
        }
    }, [data])

    useEffect(() => {
        let phase = 0;
        if (props.userEvents && props.userEvents.length > 0) {
            phase = props.userEvents[0].phase;
        }
        setPhase(phase);
    }, [props.userEvents])

    const [showDialog, setShowDialog] = useState(false);

    const userContext = useContext(UserContext);

    const [deleteRegistration] = useMutation(DELETE_USER_EVENT_QUERY);

    const handleConfirm = (eventId) => {
        deleteRegistration({
            variables: {
                userId: userContext.user.id,
                eventId: eventId
            }
        }).then(res => {
            userContext.refetch();
            setShowDialog(false);
        })
    }

    const [deleteActRegistration] = useMutation(DELETE_USER_ACT_QUERY);

    const handleActDeleteConfirm = (eventId) => {
        deleteActRegistration({
            variables: {
                userId: userContext.user.id,
                eventId: eventId
            }
        }).then(res => {
            userContext.refetch();
            setShowDialog(false);
        })
    }

    return (<>
        <Header as='h4' textAlign='center'>
            Upcoming Events
        </Header>
        <List divided relaxed>
            {eventData.length === 0
                ? (<p style={{ textAlign: 'center' }}>No events :(</p>)
                : (eventData.map(e => {
                    return (
                        <React.Fragment key={e.id}>
                            <List.Item>
                                {!props.userEvents.find(ue => ue.eventId === e.id)
                                    ? (
                                        <List.Content floated='right' style={{ margin: '6px 0 0 1em' }}>
                                            <Link to={'event/' + e.id}>
                                                <Button color='teal' primary>
                                                    Register</Button>
                                            </Link>
                                        </List.Content>
                                    ) : (
                                        <List.Content floated='right' style={{ margin: '6px 0 0 1em' }}>
                                            <Link to={'event/' + e.id}>
                                                <Button icon primary color='teal'>
                                                    <Icon name='edit' />
                                                </Button>
                                            </Link>
                                            {/* <Button icon color='red' onClick={() => setShowDialog(true)}>
                                                <Icon name='trash' />
                                            </Button>
                                            <Modal size='tiny' open={showDialog}>
                                                <Modal.Header>Delete Your Registration</Modal.Header>
                                                <Modal.Content>
                                                    <p>Are you sure you want to delete your registration</p>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button
                                                        negative
                                                        content='No'
                                                        onClick={() => setShowDialog(false)}
                                                    />
                                                    <Button
                                                        positive
                                                        icon='checkmark'
                                                        labelPosition='right'
                                                        content='Yes'
                                                        onClick={() => handleConfirm(e.id)}
                                                    />
                                                </Modal.Actions>
                                            </Modal> */}
                                            {/* {phase < 2 ?
                                                <Link to={'event/' + e.id}>
                                                    <Button color='teal' primary>
                                                        <Icon name='edit' />
                                                        Edit</Button>
                                                </Link> : null} */}
                                        </List.Content>
                                    )}
                                <List.Icon color='teal' name='calendar' size='large' verticalAlign='middle' />
                                <List.Content>
                                    <List.Header>{e.eventName}</List.Header>
                                    <List.Description>{e.eventDate}</List.Description>
                                </List.Content>
                            </List.Item>
                            {userContext.user.userType === 'dj' || userContext.user.userType === 'band' ? (
                                <List.Item style={{ borderTop: 0 }}>
                                    {!userContext.user.acts.find(ue => ue.eventId === e.id)
                                        ? (
                                            <List.Content floated='right'>
                                                <Link to={'event/' + e.id + '/act'}>
                                                    <Button color='teal' primary>
                                                        <Icon name='edit' />
                                                        Register</Button>
                                                </Link>
                                            </List.Content>
                                        ) : (
                                            <List.Content floated='right'>
                                                <Button icon color='red' onClick={() => setShowDialog(true)}>
                                                    <Icon name='trash' />
                                                </Button>
                                                <Modal size='tiny' open={showDialog}>
                                                    <Modal.Header>Delete Your Registration</Modal.Header>
                                                    <Modal.Content>
                                                        <p>Are you sure you want to delete your registration</p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            negative
                                                            content='No'
                                                            onClick={() => setShowDialog(false)}
                                                        />
                                                        <Button
                                                            positive
                                                            icon='checkmark'
                                                            labelPosition='right'
                                                            content='Yes'
                                                            onClick={() => handleActDeleteConfirm(e.id)}
                                                        />
                                                    </Modal.Actions>
                                                </Modal>
                                                {phase < 2 ?
                                                    <Link to={'event/' + e.id + '/act'}>
                                                        <Button color='teal' primary>
                                                            <Icon name='edit' />
                                                            Edit</Button>
                                                    </Link> : null}
                                            </List.Content>
                                        )}
                                    <List.Icon color='teal' name={userContext.user.userType === 'dj' ? 'sound' : 'users'} size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header>Register as {userContext.user.userType === 'dj' ? 'DJ' : 'Band'}</List.Header>
                                        <List.Description>{e.eventDate}</List.Description>
                                    </List.Content>
                                </List.Item>)
                                : null}
                        </React.Fragment>
                    )
                }))}
        </List>
    </>
    )
}

const EventStatus = (props) => {
    const [phase, setPhase] = useState(-1);
    const [eventStatus, setEventStatus] = useState({
        role: '',
        shift: '',
        buddy: ''
    });

    useEffect(() => {
        let phase = 0;
        if (props.events && props.events.length > 0) {
            phase = props.events[0].phase;
        }
        setPhase(phase);
    }, [props.events])

    switch (phase) {
        case -1:
            return (
                <Card>
                    <Card.Content>
                        <Card.Header><br /><Icon color='teal' name='file alternate outline' size='massive' /><br /><br />Register</Card.Header>
                        <Card.Meta>Check upcoming events on the right</Card.Meta>
                    </Card.Content>
                </Card>
            )
        case 0:
            return (
                <Card fluid>
                    <Card.Content>
                        <Card.Header><br /><Icon color='teal' name='file alternate outline' size='massive' /><br /><br />Register</Card.Header>
                        <Card.Meta>Check upcoming events on the right</Card.Meta>
                    </Card.Content>
                </Card>
            )
        // case 1:
        //     return (
        //         <Card fluid>
        //             <Card.Content>
        //                 <Card.Header><br /><Icon color='teal' name='time' size='massive' /><br /><br />Waiting...</Card.Header>
        //                 <Card.Meta>Your registration is being reviewed</Card.Meta>
        //             </Card.Content>
        //         </Card>
        //     )
        case 1:
            return (
                false
                    ?
                    <Link to={`event/48414295-ff52-4755-911d-a900b4e796e5/ticket`}>
                        <Card fluid>
                            <Card.Content>
                                <Card.Header><br /><Icon color='teal' name='ticket' size='massive' /><br /><br />Ticket Paid</Card.Header>
                                <Card.Meta>You have a ticket!</Card.Meta>
                            </Card.Content>
                        </Card>
                    </Link >
                    :
                    <Link to={`event/48414295-ff52-4755-911d-a900b4e796e5/ticket`}>
                        <Card fluid>
                            <Card.Content>
                                <Card.Header><br /><Icon color='teal' name='ticket' size='massive' /><br /><br />Your Ticket</Card.Header>
                                <Card.Meta>Do not forget to buy!</Card.Meta>
                            </Card.Content>
                        </Card>
                    </Link>
            )
        // case 3:
        //     return (
        //         <Card fluid>
        //             <Card.Content>
        //                 <Card.Header><br /><Icon color='teal' name='clipboard check' size='massive' /><br /><br />Shift</Card.Header>
        //                 <Card.Meta>Confirm your duties</Card.Meta>
        //             </Card.Content>
        //         </Card>
        //     )
        case 2:
            return (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>Jetzt Geht's Wieder LosLosLosheim</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Role</Table.Cell>
                            <Table.Cell>{eventStatus.role}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Shift</Table.Cell>
                            <Table.Cell>{eventStatus.shift}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Buddy</Table.Cell>
                            <Table.Cell>{eventStatus.buddy}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            )
    }
}

const EventsPage = () => {
    const userContext = useContext(UserContext);
    const history = useHistory();

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                {/* <Step.Group items={steps} attached='bottom' widths={3} />
        <br /> */}
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Events
                    </Header>
                    <Link as={Link} to={'/home'} title='Back home'>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='home' />
                        </Button>
                    </Link>
                    <Button
                        type='button'
                        floated='left'
                        color='red'
                        onClick={() => { localStorage.removeItem('auth-token'); localStorage.removeItem('email'); history.push('/') }}
                        style={{ position: 'absolute', right: 10, top: 11 }}>
                        Logout
                    </Button>
                </Message>
                <Segment attached>
                    <Grid columns={2} stackable divided>
                        <Grid.Row>
                            <Grid.Column>
                                <EventStatus events={userContext.user.events} />
                            </Grid.Column>
                            <Grid.Column>
                                <EventList userEvents={userContext.user.events} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default EventsPage