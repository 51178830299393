import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Dimmer, Loader, Form, Grid, Modal, Header, Message, Segment } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { UserContext } from '../userContext';

const GET_USER_ACT = gql`
query getUserAct($userId: uuid!) {
  user_act(where: {userId: {_eq: $userId}}) {
    eventId
    userId
    acceptRules
    firstTime
    attended
    actName
    bandSize
    estFuelCost
    guestList
    arrivalDate
    arrivalTime
    arriveEarlier
    departureDate
    departureTime
    departLater
    needsBed
    remarks
    musicStyle
    musicPreview
    gearNeed
    gearBring
    feeEnough
  }
}`;

const GET_EVENT = gql`
query getEvent($id: uuid!) {
  event(where: {id: {_eq: $id}}) {
    eventName
    eventDate
  }
}

`

const REGISTER_USER_ACT = gql`
mutation registerUserAct(
  $userId: uuid!,
  $eventId: uuid!,
  $acceptRules: Boolean!,
  $firstTime: Boolean!,
  $attended: json!,
  $actName: String!,
  $bandSize: Int,
  $estFuelCost: String,
  $guestList: String!,
  $arrivalDate: String!,
  $arrivalTime: String!,
  $arriveEarlier: Boolean!,
  $departureDate: String!,
  $departureTime: String!
  $departLater: Boolean!,
  $needsBed: Boolean!,
  $remarks: String,
  $musicStyle: String,
  $musicPreview: String,
  $gearNeed: String,
  $gearBring: String,
  $feeEnough: Boolean!
  ) {
    insert_user_act(
      objects: [
        {
          userId: $userId
          eventId: $eventId
          acceptRules: $acceptRules
          firstTime: $firstTime
          attended: $attended
          actName: $actName
          bandSize: $bandSize
          estFuelCost: $estFuelCost
          guestList: $guestList
          arrivalDate: $arrivalDate
          arrivalTime: $arrivalTime
          arriveEarlier: $arriveEarlier
          departureDate: $departureDate
          departureTime: $departureTime
          departLater: $departLater
          needsBed: $needsBed
          remarks: $remarks
          musicStyle: $musicStyle
          musicPreview: $musicPreview
          gearNeed: $gearNeed
          gearBring: $gearBring
          feeEnough: $feeEnough
        }
      ],
      on_conflict: {
        constraint: user_act_pkey,
        update_columns: [
          acceptRules,
          firstTime,
          attended,
          actName,
          bandSize,
          estFuelCost,
          guestList,
          arrivalDate,
          arrivalTime,
          arriveEarlier,
          departureDate,
          departureTime,
          departLater,
          needsBed,
          remarks,
          musicStyle,
          musicPreview,
          gearNeed,
          gearBring,
          feeEnough
        ]
      }
    ) {
    returning {
      userId
      eventId
    }
  }
}`;

const ActRegisterPage = () => {
  const userContext = useContext(UserContext)
  const eventId = useParams().eventId;

  const [formState, setFormState] = useState({
    acceptRules: false,
    firstTime: false,
    actName: '',
    bandSize: '',
    estFuelCost: '',
    guestList: '',
    arrivalDate: '',
    arrivalTime: '',
    departureDate: '',
    departureTime: '',
    arriveEarlier: false,
    departLater: false,
    needsBed: false,
    remarks: '',
    musicStyle: '',
    musicPreview: '',
    gearNeed: '',
    gearBring: '',
    feeEnough: false
  });

  const [attendance, setAttendance] = useState({
    jetztgeht: false,
    bremsen: false,
    leinen: false,
    hullen: false,
    schrauben: false
  });

  const handleAttendance = (e, data) => {
    setAttendance({
      ...attendance,
      [data.name]: data.checked
    });
  }

  const [stepNo, setStepNo] = useState(0);

  // Get Event Data 
  const eventQuery = useQuery(GET_EVENT, { variables: { id: eventId } });
  const [eventState, setEventState] = useState({
    name: '',
    date: ''
  })

  useEffect(() => {
    if (eventQuery.data && eventQuery.data.event) {
      setEventState({
        name: eventQuery.data.event[0].eventName,
        date: eventQuery.data.event[0].eventDate
      })
    }
  }, [eventQuery.data]);


  // eslint-disable-next-line
  const { loading, error, data } = useQuery(GET_USER_ACT, {
    variables: { userId: userContext.user.id },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.user_act[0]) {
      let registerForm = data.user_act[0];
      setFormState({
        ...formState,
        ...registerForm
      });
      setAttendance(registerForm.attended);
    }
  }, [data]);

  const handleFormChange = (e, data) => {
    setFormState({
      ...formState,
      [data.name]: data.value === undefined ? data.checked : data.value
    })
  }

  console.log('Re-render!');

  const [registerUserEvent] = useMutation(REGISTER_USER_ACT);

  const handleSubmit = () => {
    console.log(formState);
    registerUserEvent({
      variables: {
        userId: userContext.user.id,
        eventId: eventId,
        acceptRules: formState.acceptRules,
        firstTime: formState.firstTime,
        attended: attendance,
        actName: formState.actName,
        bandSize: formState.bandSize !== '' ? formState.bandSize : 0,
        estFuelCost: formState.estFuelCost,
        guestList: formState.guestList,
        arrivalDate: formState.arrivalDate,
        arrivalTime: formState.arrivalTime,
        arriveEarlier: formState.arriveEarlier,
        departureDate: formState.departureDate,
        departureTime: formState.departureTime,
        departLater: formState.departLater,
        needsBed: formState.needsBed,
        remarks: formState.remarks,
        musicStyle: formState.musicStyle,
        musicPreview: formState.musicPreview,
        gearNeed: formState.gearNeed,
        gearBring: formState.gearBring,
        feeEnough: formState.feeEnough
      }
    }).then(res => {
      userContext.refetch();
      console.log(res);
    })
  }

  const bandSizeOptions = [
    { key: 1, value: 1, text: '1' },
    { key: 2, value: 2, text: '2' },
    { key: 3, value: 3, text: '3' },
    { key: 4, value: 4, text: '4' },
    { key: 5, value: 5, text: '5' },
    { key: 6, value: 6, text: '6' },
    { key: 7, value: 7, text: '7' },
    { key: 8, value: 8, text: '8' },
    { key: 9, value: 9, text: '9' },
    { key: 10, value: 10, text: '10' },
  ]

  const arrivalDateOptions = [
    { key: 'thu', value: 'thu', text: 'Thursday 21 May' },
    { key: 'fri', value: 'fri', text: 'Friday 22 May' }
  ]

  const departureDateOptions = [
    { key: 'sun', value: 'sun', text: 'Sunday 24 May' },
    { key: 'mon', value: 'mon', text: 'Monday 25 May' }
  ]

  const timeOfDayOptions = [
    { key: 'morning', value: 'morning', text: 'In the morning' },
    { key: 'afternoon', value: 'afternoon', text: 'In the afternoon' },
    { key: 'evening', value: 'evening', text: 'In the evening' },
    { key: 'night', value: 'night', text: 'In the night' },
  ]

  const [showModal, setShowModal] = useState(false);
  const [showModalFirstTime, setShowModalFirstTime] = useState(false);
  const [showModalFeeEnough, setShowModalFeeEnough] = useState(false);

  const registerSteps = (stepNo) => {
    switch (stepNo) {
      case 0:
        return (
          <Form className='attached fluid segment'>
            <h3>The House Rule of LandGut Girtenmühle</h3>
            <p>
              The LosLosLosheim festivals are somewhat special,
              a little different than you might expect of your average party.
              We started as a group of friends celebrating LandGut Girtenmühle
              and all that it stands for.. and we aim to keep it that way!
            </p>
            <p>
              The most important fact to keep in mind is that this festival
              is actually an oversized houseparty. <b>This is our home</b>, the place where
              we live. In the last years, we have been working our asses off to transform
              this place into a little paradise.
            </p>
            <p>
              <u>We ask you all to treat it with care and give it the respect it deserves.</u>
            </p>
            <Form.Group grouped>
              <Form.Checkbox
                label='I understand'
                name='acceptRules'
                key='acceptRules'
                checked={formState.acceptRules}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Modal size='tiny' open={showModal}>
              <Modal.Header>House Rule</Modal.Header>
              <Modal.Content>
                <p>Please accept our house rule</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='OK'
                  onClick={() => setShowModal(false)}
                />
              </Modal.Actions>
            </Modal>
            <h3>Is this the first time you will join us?</h3>
            <Form.Group inline>
              <Form.Radio
                label='Yes'
                name='firstTime'
                key='firstTimeYes'
                checked={formState.firstTime}
                onChange={() => { setFormState({ ...formState, firstTime: !formState.firstTime }); setShowModalFirstTime(true) }}
              />
              <Form.Radio
                label='No'
                name='firstTime'
                key='firstTimeNo'
                checked={!formState.firstTime}
                onChange={() => setFormState({ ...formState, firstTime: !formState.firstTime })}
              />
            </Form.Group>
            {!formState.firstTime ? (
              <Form.Group grouped>
                <label>No, I have attended</label>
                <Form.Checkbox
                  label='Jetzt Geht LosLosLosheim'
                  name='jetztgeht'
                  checked={attendance.jetztgeht}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Bremsen LosLosLosheim'
                  name='bremsen'
                  checked={attendance.bremsen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Leinen LosLosLosheim'
                  name='leinen'
                  checked={attendance.leinen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Hullen LosLosLosheim'
                  name='hullen'
                  checked={attendance.hullen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Schrauben LosLosLosheim'
                  name='schrauben'
                  checked={attendance.schrauben}
                  onChange={handleAttendance}
                />
              </Form.Group>
            ) : (null)}
            <Modal size='tiny' open={showModalFirstTime}>
              <Modal.Header>First Time Information</Modal.Header>
              <Modal.Content>
                <p>Liebes Mensch,</p>
                <p>Remember that awesome festival just across the border? Guess what.. we’re having another one!
                  Last year was a huge success and we plan on making this next one bigger and a lot better. This will
                  be a special themed edition: <b>Alle Tiere LosLosLosheim</b></p>
                <p>As you all know the goal of last years festival was to take back control over the amount of visitors
                  and return to the small festival you’ve all grown to love. And we succeeded in doing just that.
                  We’ve welcomed around 400 people, everyone contributed to the festival and the group felt like one
                  big family again. We are very happy that many of you joined our team! This year we aim for a maximum
                  of 600 people and will manage the flow of people better to improve the occupation of the stages.</p>
                <p>This will be the sixth edition of our LosLosLosheim festivals and we are proud of how much the quality
                  improved over the years. We’ve put a lot of time and effort in organising them and have always tried
                  to make them affordable for you, but we ended up either losing money or barely breaking even... Therefore
                  we’ve decided to raise the ticket price a little bit and we hope you can understand. If we manage to
                  make a small profit this edition, we will buy our first animals! We will start with chickens and will
                  later expand our small farm with some goats and… who knows? We will make a Poll on the facebook page
                  to enable everyone to add their favourite names; the most voted names will be given to our first chicks!
                 </p>
                <p>
                  - Team Girtenmühle
                 </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='OK'
                  onClick={() => setShowModalFirstTime(false)}
                />
              </Modal.Actions>
            </Modal>
            <Button
              type='button'
              color='teal'
              fluid
              size='large'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => formState.acceptRules ? setStepNo(stepNo + 1) : setShowModal(true)} />
            <Dimmer active={loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Form >
        )
      case 1:
        return (
          <Form className='attached fluid segment'>
            <h3>Artist Registration</h3>
            <p>
              Thank you all for wanting to contribute to this edition of our LosLosLosheim festivals!
              We are only three years old and have already thrown several of the best parties this part
              of Germany has seen in a while… and all thanks to you guys, our artists, of which many choose
              to travel hundreds of miles to be here.
            </p>
            <Form.Input
              label='Name of your act'
              name='actName'
              value={formState.actName}
              onChange={handleFormChange}
              fluid
              //icon='phone'
              //iconPosition='left'
              placeholder='Name of your act'
            />
            {userContext.user.userType === 'band' ?
              <Form.Select
                label='Size of your band'
                name='bandSize'
                value={formState.bandSize}
                onChange={handleFormChange}
                fluid
                placeholder='How many band members?'
                options={bandSizeOptions}
              /> : null}
            <p>
              {userContext.user.userType === 'band' ?
                'Because we are still in the startup phase it is not possible for us to pay high fees to our artists, even though we want to! Instead we would like to compensate for the costs our artists make during the event. Each band member will receive 25 coins (worth € 75,-) and can bring one guest (free of charge, no need to work). We will also cover the fuel costs for the band!' :
                'Because we are still in the startup phase it is not possible for us to pay high fees to our artists, instead, we compensate by giving you and someone of your choice a free ticket, and coins for some drinks.'}
            </p>
            <Form.Checkbox
              label='I think that is enough'
              name='feeEnough'
              key='feeEnough'
              checked={formState.feeEnough}
              onChange={handleFormChange}
            />
            {userContext.user.userType === 'band' ?
              <Form.Input
                label='Estimated fuel cost'
                name='estFuelCost'
                value={formState.estFuelCost}
                onChange={handleFormChange}
                fluid
                placeholder='20 €'
              /> : null}
            <br />
            <p>
              Who would you like to bring? (you can change this later)
            </p>
            <Form.Input
              label='Guestlist'
              name='guestList'
              value={formState.guestList}
              onChange={handleFormChange}
              fluid
              //icon='phone'
              //iconPosition='left'
              placeholder='Name of your guest'
            />
            <p>
              <b>{userContext.user.userType === 'band' ?
                'These people do not have to make an account or application on our site, unless they want to be scheduled for a shift (which would be nice, of course)' :
                'This person does not have to make an account or application on our site, unless she/he wants to be scheduled for a shift (which would be nice, of course)'}
              </b>
            </p>
            <Modal size='tiny' open={showModalFeeEnough}>
              <Modal.Header>Fee</Modal.Header>
              <Modal.Content>
                Please accept our compensation.
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='OK'
                  onClick={() => setShowModalFeeEnough(false)}
                />
              </Modal.Actions>
            </Modal>
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => formState.feeEnough ? setStepNo(stepNo + 1) : setShowModalFeeEnough(true)} />
            <br /><br />
          </Form>)
      case 2:
        return (
          <Form className='attached fluid segment'>
            <p style={{ marginTop: 0 }}>
              {userContext.user.userType === 'band' ?
                'We ask all our artists to bring as much of their own gear as possible and we will rent whatever is needed. It could really help if some artists would be willing to share equipment. We ask all drummers to bring their own “breakables” (bekkens, pedals, hi hat).' :
                'We ask all our artists to bring as much of their own gear as possible and we will rent whatever is needed. It could really help if some artists would be willing to share equipment.'}
            </p>
            <p>
              We will send you a technical rider with all the equipment that will be available for you.
                      </p>
            {userContext.user.userType === 'band' ?
              null :
              (<>
                <Form.Input
                  label='What style would you like to play'
                  name='musicStyle'
                  value={formState.musicStyle}
                  onChange={handleFormChange}
                  fluid
                  //icon='phone'
                  //iconPosition='left'
                  placeholder='Music Style'
                />
                <Form.Input
                  label='Preview to your music / style'
                  name='musicPreview'
                  value={formState.musicPreview}
                  onChange={handleFormChange}
                  fluid
                  //icon='phone'
                  //iconPosition='left'
                  placeholder='Preview of music / style'
                />
                <Form.Input
                  label='What gear do you need'
                  name='gearNeed'
                  value={formState.gearNeed}
                  onChange={handleFormChange}
                  fluid
                  //icon='phone'
                  //iconPosition='left'
                  placeholder='Gear that you need'
                />
                <Form.Input
                  label='What gear can you bring'
                  name='gearBring'
                  value={formState.gearBring}
                  onChange={handleFormChange}
                  fluid
                  //icon='phone'
                  //iconPosition='left'
                  placeholder='Gear that you can bring'
                />
              </>)}
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='check'
              labelPosition='right'
              content='Submit'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form>)
      case 3:
        return (
          <Form className='attached fluid segment' onSubmit={null}>
            <p style={{ marginTop: 0 }}>
              As we try to make a solid schedule, we would like to know when all of you will arrive.
              You can always update this info in case your planning changes.
            </p>
            <Form.Group widths='equal'>
              <Form.Select
                label='Arrival Date'
                name='arrivalDate'
                value={formState.arrivalDate}
                onChange={handleFormChange}
                placeholder='Arrival Date'
                options={arrivalDateOptions}
              />
              <Form.Select
                label='Arrival Time'
                name='arrivalTime'
                value={formState.arrivalTime}
                onChange={handleFormChange}
                placeholder='Arrival Time Of Day'
                options={timeOfDayOptions}
              />
            </Form.Group>
            <p>
              We’ve noticed some people kept on chilling on the campsite after the festival.
              Of course, we want you to rest well before heading back! From our end, we need
              to tidy up the mess and open the campsite for regular guests as soon as possible.  Therefore, we’ve decided
              everyone is allowed to stay until Monday, on the condition that they leave that evening.
            </p>
            <Form.Group widths='equal'>
              <Form.Select
                label='Departure Date'
                name='departureDate'
                value={formState.departureDate}
                onChange={handleFormChange}
                placeholder='Departure Date'
                options={departureDateOptions}
              />
              <Form.Select
                label='Departure Time'
                name='departureTime'
                value={formState.departureTime}
                onChange={handleFormChange}
                placeholder='Departure Time Of Day'
                options={timeOfDayOptions}
              />
            </Form.Group>
            <p>
              If you have the time we would like to ask you to come a bit earlier or stay longer
              to help us before and after the festival! We will install lights, artwork and
              build other cool stuff. We hope some creative people will attend so we can make
              this event look the part too. After the festival and resting for a bit, we will be cleaning up and
              taking everything down to transform it back to a ‘normal’ campsite again. We could
              always use some extra hands! Let us know if you want to help out and please ask your friends!
            </p>
            <Form.Group widths='equal'>
              <Form.Checkbox
                label='I can come a bit sooner!'
                name='arriveEarlier'
                key='arriveEarlier'
                checked={formState.arriveEarlier}
                onChange={handleFormChange}
              />
              <Form.Checkbox
                label='I can stay longer!'
                name='departLater'
                key='departLater'
                checked={formState.departLater}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form >)
      case 4:
        return (
          <Form className='attached fluid segment' onSubmit={null}>
            <p style={{ marginTop: 0 }}>
              We do not have many rooms in our hotel, so please bring a tent and secure a good spot on our campsite.
              We would like to reserve the rooms for the disabled and those in desperate need for a regular bed.
            </p>
            <Form.Checkbox
              label='I really need a regular bed'
              name='needsBed'
              key='needsBed'
              checked={formState.needsBed}
              onChange={handleFormChange}
            />
            <Form.TextArea
              label='Would you like to share anything else?'
              placeholder='Tell us more'
              name='remarks'
              value={formState.remarks}
              onChange={handleFormChange} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => { handleSubmit(); setStepNo(stepNo + 1) }} />
            <br /><br />
          </Form>)
      case 5:
        return (<Segment attached textAlign='center'>
          <h3>Thanks for filling everything out!</h3>
          You can change your information at any time, please make sure your arrival
          and departure dates are up to date! We will call you as soon as possible, bis bald!
          </Segment>)
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 650 }}>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Artist Registration for {eventState.name}
          </Header>
          {eventState.date}
        </Message>
        <div style={{ textAlign: 'left' }}>
          {registerSteps(stepNo)}
        </div>
        <Message attached='bottom' info>
          Back to <Link to='/events'>events</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default ActRegisterPage