import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Dimmer, Grid, Header, Loader, Message, Icon, Segment } from 'semantic-ui-react'
import { UserContext } from '../userContext';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import QRCode from "react-qr-code";

const GET_TICKET = gql`
    query GetTicket($userId: uuid!, $eventId: uuid!) {
        ticket(where: {userid: {_eq: $userId}, eventid: {_eq: $eventId}, transaction: {status: {_eq: "paid"}, amountrefunded: {_eq: 0}}}) {
            id
            eventid
            userid
            transactionid
            transaction {
                amount
                status
            }
        }
    }  
`;

const BuyTicketPage = () => {
    const userContext = useContext(UserContext);
    const history = useHistory();
    const params = useParams();

    const { loading, error, data, refetch } = useQuery(GET_TICKET, {
        variables: {
            userId: userContext.user.id,
            eventId: params.eventId
        },
        fetchPolicy: "network-only"
    });

    const [paymentLink, setPaymentLink] = useState('');

    useEffect(() => {
        async function getMollieLink() {
            console.log(userContext);
            const response = await fetch(`https://api.lggm.de/mollie?name=${userContext.user.firstName} ${userContext.user.lastName}&email=${userContext.user.email}&eventId=${params.eventId}&userId=${userContext.user.id}`);
            const mollie = await response.json();
            console.log(mollie.paymentLink);
            setPaymentLink(mollie.paymentLink)
        }

        if (!userContext.user.id || error || loading) return;
        if (data && data.ticket.length > 0) return;

        getMollieLink();
    }, [userContext, data])

    // Function will execute on click of button
    const downloadTicket = async () => {
        setGeneratingTicket(true);
        // using Java Script method to get PDF file
        await fetch(`https://api.lggm.de/ticket?email=${encodeURIComponent(userContext.user.email)}`).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `ticketJGWLLL${userContext.user.firstName}.pdf`;
                alink.click();
                setGeneratingTicket(false);
            })
        })
    }

    const [generatingTicket, setGeneratingTicket] = useState(false)

    if (generatingTicket) {
        return (
            <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Message attached style={{ position: 'relative' }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Events
                        </Header>
                        <Link as={Link} to={'/home'}>
                            <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                                <Icon name='home' />
                            </Button>
                        </Link>
                        <Button
                            type='button'
                            floated='left'
                            color='red'
                            onClick={() => { localStorage.removeItem('auth-token'); localStorage.removeItem('email'); history.push('/') }}
                            style={{ position: 'absolute', right: 10, top: 11 }}>
                            Logout
                        </Button>
                    </Message>
                    <Segment attached style={{ height: 100 }}>
                        <Dimmer active inverted>
                            <Loader inverted>Generating your ticket...</Loader>
                        </Dimmer>
                    </Segment>
                </Grid.Column>
            </Grid >
        )
    }

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                {/* <Step.Group items={steps} attached='bottom' widths={3} />
    <br /> */}
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Events
                    </Header>
                    <Link as={Link} to={'/home'}>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='home' />
                        </Button>
                    </Link>
                    <Button
                        type='button'
                        floated='left'
                        color='red'
                        onClick={() => { localStorage.removeItem('auth-token'); localStorage.removeItem('email'); history.push('/') }}
                        style={{ position: 'absolute', right: 10, top: 11 }}>
                        Logout
                    </Button>
                </Message>
                <Segment attached>
                    {/* <Grid columns={2} stackable divided>
                        <Grid.Row>
                            <Grid.Column>
                                <EventStatus events={userContext.user.events} />
                            </Grid.Column>
                            <Grid.Column>
                                <EventList userEvents={userContext.user.events} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> */}
                    {data && data.ticket.length > 0
                        ? (<div>
                            <h3>Congratulations, you have a ticket!</h3>
                            <p>
                                <Icon name='check' size='massive' color='green' style={{ verticalAlign: 'top', marginRight: '.2em' }} />
                                <QRCode value={data.ticket[0].id} size={112} />
                            </p>
                            <p>
                                <Button
                                    type='button'
                                    color='teal'
                                    onClick={downloadTicket}
                                >
                                    Download Ticket
                                </Button>
                            </p>
                            <p>
                                Please know there is no need to print your ticket
                            </p>
                        </div>)
                        : paymentLink
                            ? (<>
                                <a href={paymentLink}>
                                    <Icon name='shopping cart' size='massive' color='teal' style={{ marginTop: '20px' }} />
                                </a>
                                <h3>Please buy your ticket <a href={paymentLink}>here! </a></h3>
                                <p>This ticket includes camping</p>
                            </>
                            )
                            : <Icon loading name='spinner' />}
                </Segment>
                <Message attached='bottom' info>
                    Back to <Link to='/events'>events</Link>
                </Message>
            </Grid.Column>
        </Grid>
    )
}

export default BuyTicketPage