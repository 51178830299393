import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Header, Message } from 'semantic-ui-react';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => new URLSearchParams(useLocation().search);

function VerifyEmailPage() {
  const [verified, setVerified] = useState(false)
  let query = useQuery();
  let email = query.get('email');
  let verifyToken = query.get('verifyToken');

  useEffect(() => {
    fetch('https://api.lggm.de/verifyEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, verifyToken })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setVerified(true);
      })
  },
    // eslint-disable-next-line 
    [])

  return (
    <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Email Verification
          </Header>
        </Message>
        <Message attached info>
          Your email has {verified ? '' : 'not'} been verified. Go to <Link to='/login'>login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default VerifyEmailPage