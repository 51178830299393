import React, { } from 'react';
import { Grid, Header, Message, Segment, Icon } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_TICKET = gql`
    query GetTicket($userId: uuid!, $eventId: uuid!) {
        ticket(where: {userid: {_eq: $userId}, eventid: {_eq: $eventId}, transaction: {status: {_eq: "paid"}, amountrefunded: {_eq: 0}}}) {
            id
            eventid
            userid
            transactionid
            transaction {
                amount
                status
            }
        }
    }  
`;

const StatsPage = () => {
    // const { loading, error, data, refetch } = useQuery(GET_TICKET, {
    //     variables: {
    //     },
    //     fetchPolicy: "network-only"
    // });

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Stats
                    </Header>
                </Message>
                <Segment attached>
                    <Header as='h3' textAlign='center'><Icon name='user' /> Accounts created</Header>
                    <Header as='h3' textAlign='center'><Icon name='calendar' /> Event signups</Header>
                    <Header as='h3' textAlign='center'><Icon name='ticket' /> Tickets sold</Header>
                    <Header as='h3' textAlign='center'><Icon name='user secret' /> Captain signups</Header>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default StatsPage