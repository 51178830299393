import React, { } from 'react';
import { Button, Grid, Header, Message, Segment, Icon, Table, Dimmer, Loader } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link, useParams } from 'react-router-dom';

const GET_RIDE = gql`
    query GetRide($rideId: uuid!) {
        rideshare(where: {id: {_eq: $rideId}}) {
            id
            userId
            departFrom
            departFromDate
            departFromTime
            spots
            shareMail
            sharePhone
            user {
                firstName
            }
        }
    }  
`;

const RideShareRidePage = () => {
    const rideId = useParams().rideId;

    const { loading, error, data, refetch } = useQuery(GET_RIDE, {
        variables: {
            rideId: rideId
        },
        // fetchPolicy: "network-only"
    });

    if (loading) {
        return (
            <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Message attached style={{ position: 'relative' }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Rideshare
                        </Header>
                    </Message>
                    <Segment attached style={{ height: 100 }}>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                </Grid.Column>
            </Grid >
        )
    }

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Rideshare
                    </Header>
                    <Link as={Link} to={'/rideshare'} title='Back to rideshare overview'>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='car' />
                        </Button>
                    </Link>
                </Message>
                <Segment attached>
                    <Header as='h4' textAlign='center'>
                        Ride with {data.rideshare[0].user.firstName}
                    </Header>
                    <b>From:</b> {data.rideshare[0].departFrom}<br />
                    <b>Date:</b> {data.rideshare[0].departFromDate}<br />
                    <b>Time:</b> {data.rideshare[0].departFromTime}<br />
                    <b>Spots available:</b> {data.rideshare[0].spots}
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default RideShareRidePage