import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Checkbox, Dimmer, Loader, Form, Grid, Modal, Header, Message, Icon, Table, Segment } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { UserContext } from '../userContext';
import QRCode from "react-qr-code";

import bg1 from '../images/bg1.png';
import bg2 from '../images/bg2.png';
import bg3 from '../images/bg3.png';
import bg4 from '../images/bg4.png';
import bg5 from '../images/bg5.png';

// Background images
const background = [bg1, bg1, bg2, bg3, bg4, bg5, bg5];

function setBackground(newBackground) {
  document.documentElement.style.setProperty('--backgroundImage', `url(${newBackground})`);
}

const GET_USER_EVENT_AND_TICKET = gql`
query getUserEvent($userId: uuid!, $eventId: uuid!) {
  user_event(where: {userId: {_eq: $userId}, eventId: {_eq: $eventId}}) {
    userId
    eventId
    acceptRules
    firstTime
    introducedBy
    attended
    preferredBuddy
    preferredRole
    backupRole
    preferredShift
    remarks
    signedUp
    departureDate
    departureTime
    departLater
    biggerRole
    arrivalDate
    arrivalTime
    arriveEarlier
    callMatrix
  }
  ticket(where: {userid: {_eq: $userId}, eventid: {_eq: $eventId}, transaction: {status: {_eq: "paid"}, amountrefunded: {_eq: 0}}}) {
    id
    eventid
    userid
    transactionid
    transaction {
        amount
        status
    }
  }
}`;

const GET_EVENT = gql`
query getEvent($id: uuid!) {
  event(where: {id: {_eq: $id}}) {
    eventName
    eventDate
  }
}`;

const REGISTER_USER_EVENT = gql`
mutation registerUserEvent(
  $userId: uuid!,
  $eventId: uuid!,
  $acceptRules: Boolean!,
  $firstTime: Boolean!,
  $introducedBy: String!,
  $attended: json!,
  $preferredRole: String!,
  $backupRole: String!,
  $preferredShift: String!,
  $preferredBuddy: String,
  $arrivalDate: String!,
  $arrivalTime: String!,
  $departureDate: String!,
  $departureTime: String!,
  $arriveEarlier: Boolean!,
  $departLater: Boolean!,
  $biggerRole: Boolean!,
  $remarks: String,
  $callMatrix: json
  ) {
    insert_user_event(
      objects: [
        {
          userId: $userId
          eventId: $eventId
          acceptRules: $acceptRules
          firstTime: $firstTime
          introducedBy: $introducedBy
          attended: $attended
          preferredRole: $preferredRole
          backupRole: $backupRole
          preferredShift: $preferredShift
          preferredBuddy: $preferredBuddy
          arrivalDate: $arrivalDate
          arrivalTime: $arrivalTime
          departureDate: $departureDate
          departureTime: $departureTime
          arriveEarlier: $arriveEarlier
          departLater: $departLater
          biggerRole: $biggerRole
          remarks: $remarks
          callMatrix: $callMatrix
        }
      ],
      on_conflict: {
        constraint: user_event_pkey,
        update_columns: [
          acceptRules,
          firstTime,
          introducedBy,
          attended,
          preferredRole,
          backupRole,
          preferredShift,
          preferredBuddy,
          arrivalDate,
          arrivalTime,
          departureDate,
          departureTime,
          arriveEarlier,
          departLater,
          biggerRole,
          remarks,
          callMatrix
        ]
      }
    ) {
    returning {
      userId
      eventId
    }
  }
}`;

const EventRegisterPage = () => {
  const userContext = useContext(UserContext)
  const eventId = useParams().eventId;

  const [formState, setFormState] = useState({
    acceptRules: false,
    firstTime: false,
    introducedBy: '',
    preferredRole: '',
    backupRole: '',
    preferredShift: '',
    preferredBuddy: '',
    arrivalDate: '',
    arrivalTime: '',
    departureDate: '',
    departureTime: '',
    arriveEarlier: false,
    departLater: false,
    biggerRole: false,
    remarks: '',
    event: {
      eventName: '',
      eventDate: ''
    }
  });

  const [attendance, setAttendance] = useState({
    jetztgeht: false,
    bremsen: false,
    leinen: false,
    hullen: false,
    schrauben: false
  });

  const handleAttendance = (e, data) => {
    setAttendance({
      ...attendance,
      [data.name]: data.checked
    });
  }

  const [stepNo, setStepNo] = useState(0);

  const [callMatrix, setCallMatrix] = useState({
    mo1: false,
    mo2: false,
    tu1: false,
    tu2: false,
    we1: false,
    we2: false,
    th1: false,
    th2: false,
    fr1: false,
    fr2: false,
    wknd1: false,
    wknd2: false
  });

  const handleCallMatrix = (e, data) => {
    // console.log(data);
    setCallMatrix({
      ...callMatrix,
      [data.name]: data.checked
    });
  }

  // Get Event Data 
  const eventQuery = useQuery(GET_EVENT, { variables: { id: eventId } });
  const [eventState, setEventState] = useState({
    name: '',
    date: ''
  })

  useEffect(() => {
    if (eventQuery.data && eventQuery.data.event) {
      setEventState({
        name: eventQuery.data.event[0].eventName,
        date: eventQuery.data.event[0].eventDate
      })
    }
  }, [eventQuery.data]);


  // eslint-disable-next-line
  const { loading, error, data } = useQuery(GET_USER_EVENT_AND_TICKET, {
    variables: { userId: userContext.user.id, eventId: eventId },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.user_event[0]) {
      let registerForm = data.user_event[0];
      setFormState({
        ...formState,
        ...registerForm
      });
      setAttendance(registerForm.attended);
      setCallMatrix(registerForm.callMatrix);
    }
  }, [data]);

  const handleFormChange = (e, data) => {
    setFormState({
      ...formState,
      [data.name]: data.value === undefined ? data.checked : data.value
    })
  }

  console.log('Re-render!');

  const [registerUserEvent] = useMutation(REGISTER_USER_EVENT);

  const handleSubmit = () => {
    console.log(formState);
    registerUserEvent({
      variables: {
        userId: userContext.user.id,
        eventId: eventId,
        acceptRules: formState.acceptRules,
        firstTime: formState.firstTime,
        introducedBy: formState.introducedBy,
        attended: attendance,
        preferredRole: formState.preferredRole,
        backupRole: formState.backupRole,
        preferredShift: formState.preferredShift,
        preferredBuddy: formState.preferredBuddy,
        arrivalDate: formState.arrivalDate,
        arrivalTime: formState.arrivalTime,
        departureDate: formState.departureDate,
        departureTime: formState.departureTime,
        arriveEarlier: formState.arriveEarlier,
        departLater: formState.departLater,
        biggerRole: formState.biggerRole,
        remarks: formState.remarks,
        callMatrix: callMatrix
      }
    }).then(res => {
      userContext.refetch();
      console.log(res);
    })
  }

  const roleOptions = [
    { key: 'bar', value: 'bar', text: 'Bar' },
    { key: 'facility', value: 'facility', text: 'Facility' },
    { key: 'mood', value: 'mood', text: 'Mood Setting' },
    { key: 'entrance', value: 'entrance', text: 'Entrance' },
    { key: 'parking', value: 'parking', text: 'Parking' },
    // { key: 'building', value: 'building', text: 'Building' }
  ]

  const shiftOptions = [
    { key: 'morning', value: 'morning', text: 'Morning shift' },
    { key: 'day', value: 'day', text: 'Day shift' },
    { key: 'night', value: 'night', text: 'Night shift' },
  ]

  const arrivalDateOptions = [
    { key: '2023-05-11', value: '2023-05-11', text: 'Thu 11 May (Building Crew)' },
    { key: '2023-05-12', value: '2023-05-12', text: 'Fri 12 May (Building Crew)' },
    { key: '2023-05-13', value: '2023-05-13', text: 'Sat 13 May (Building Crew)' },
    { key: '2023-05-14', value: '2023-05-14', text: 'Sun 14 May (Building Crew)' },
    { key: '2023-05-15', value: '2023-05-15', text: 'Mon 15 May (Building Crew)' },
    { key: '2023-05-16', value: '2023-05-16', text: 'Tue 16 May (Building Crew)' },
    { key: '2023-05-17', value: '2023-05-17', text: 'Wed 17 May' },
    { key: '2023-05-18', value: '2023-05-18', text: 'Thu 18 May' },
    { key: '2023-05-19', value: '2023-05-19', text: 'Fri 19 May' },
    { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' }
  ]

  const departureDateOptions = [
    { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' },
    { key: '2023-05-21', value: '2023-05-21', text: 'Sun 21 May' },
    { key: '2023-05-22', value: '2023-05-22', text: 'Mon 22 May' },
    { key: '2023-05-23', value: '2023-05-23', text: 'Tue 23 May (Breakdown Crew)' },
    { key: '2023-05-24', value: '2023-05-24', text: 'Wed 24 May (Breakdown Crew)' },
    { key: '2023-05-25', value: '2023-05-25', text: 'Thu 25 May (Breakdown Crew)' }
  ]

  const timeOfDayOptions = [
    { key: 'morning', value: 'morning', text: 'In the morning' },
    { key: 'afternoon', value: 'afternoon', text: 'In the afternoon' },
    { key: 'evening', value: 'evening', text: 'In the evening' },
    { key: 'night', value: 'night', text: 'In the night' },
  ]

  const [showModal, setShowModal] = useState(false);
  const [showModalFirstTime, setShowModalFirstTime] = useState(false);

  const [paymentLink, setPaymentLink] = useState('');

  // const { ticketloading, ticketerror, ticketdata, refetch } = useQuery(GET_TICKET, {
  //   variables: {
  //     userId: userContext.user.id,
  //     eventId: eventId
  //   },
  //   fetchPolicy: "network-only"
  // });

  useEffect(() => {
    async function getMollieLink() {
      console.log(userContext);
      const response = await fetch(`https://api.lggm.de/mollie?name=${userContext.user.firstName} ${userContext.user.lastName}&email=${userContext.user.email}&eventId=${eventId}&userId=${userContext.user.id}`);
      const mollie = await response.json();
      console.log(mollie.paymentLink);
      setPaymentLink(mollie.paymentLink)
    }

    if (!userContext.user.id || error || loading) return;
    if (data && data.ticket.length > 0) return;
    getMollieLink();
  }, [userContext, data])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stepNo])

  setBackground(background[stepNo]);

  const registerSteps = (stepNo) => {
    switch (stepNo) {
      case 0:
        return (
          <Form className='attached fluid segment'>
            <h3>The house rule of LandGut Girtenmühle</h3>
            <p>
              The LosLosLosheim festivals are somewhat special,
              a little different than you might expect of your average party.
              We started as a group of friends celebrating LandGut Girtenmühle
              and all that it stands for... and we aim to keep it that way!
            </p>
            <p>
              The most important fact to keep in mind is that this festival
              is actually an oversized houseparty. <b>This is our home</b>, the place where
              we live. In the last years, we have been working our asses off to transform
              this place into a little paradise.
            </p>
            <p>
              <u>We ask you all to treat it with care and give it the respect it deserves.</u>
            </p>
            <Form.Group grouped>
              <Form.Checkbox
                label='I understand'
                name='acceptRules'
                key='acceptRules'
                checked={formState.acceptRules}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Modal size='tiny' open={showModal}>
              <Modal.Header>House Rule</Modal.Header>
              <Modal.Content>
                <p>Please accept our house rule</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='OK'
                  onClick={() => setShowModal(false)}
                />
              </Modal.Actions>
            </Modal>
            <h3>Is this the first time you will join us?</h3>
            <Form.Group inline>
              <Form.Radio
                label='Yes'
                name='firstTime'
                key='firstTimeYes'
                checked={formState.firstTime}
                onChange={() => { setFormState({ ...formState, firstTime: !formState.firstTime }); setShowModalFirstTime(true) }}
              />
              <Form.Radio
                label='No'
                name='firstTime'
                key='firstTimeNo'
                checked={!formState.firstTime}
                onChange={() => setFormState({ ...formState, firstTime: !formState.firstTime })}
              />
            </Form.Group>
            {!formState.firstTime ? (
              <Form.Group grouped>
                <label>No, I have attended</label>
                <Form.Checkbox
                  label="Jetzt Geht's LosLosLosheim!"
                  name='jetztgeht'
                  checked={attendance.jetztgeht}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Bremsen LosLosLosheim!'
                  name='bremsen'
                  checked={attendance.bremsen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Leinen LosLosLosheim!'
                  name='leinen'
                  checked={attendance.leinen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Hullen LosLosLosheim!'
                  name='hullen'
                  checked={attendance.hullen}
                  onChange={handleAttendance}
                />
                <Form.Checkbox
                  label='Alle Schrauben LosLosLosheim!'
                  name='schrauben'
                  checked={attendance.schrauben}
                  onChange={handleAttendance}
                />
              </Form.Group>
            ) : (
              <Form.Input
                label='Who introduced you to LosLosLosheim?'
                name='introducedBy'
                value={formState.introducedBy}
                onChange={handleFormChange}
                fluid
                placeholder='Name of your friend'
              />
            )}
            <Modal size='tiny' open={showModalFirstTime}>
              <Modal.Header>First Time Information</Modal.Header>
              <Modal.Content>
                <p>
                  Liebes Mensch,
                </p>
                <p>
                  So you’ve heard something about an awesome festival just across the border?
                  Guess what.. we’re having another one! Due to corona, we weren't able to host a festival for 3
                  YEARS…
                </p>
                <p>
                  But <b>Jetzt Geht’s Wieder LosLosLosheim!</b>
                </p>
                <p>
                  The LosLosLosheim festivals started as a “thank you” for all our friends and family that helped us
                  during the first months of renovating the campsite. And over the years it grew into an intimate
                  festival with friends. And now you too, have been invited (probably by a friend) to join the party!
                </p>
                <p>
                  During our festivals everyone helps out, this means you will have to choose a shift and contribute
                  to the festival for a couple of hours. This way we make this event possible together and it really
                  means you are part of the party! :D
                </p>
                <p>This will be the sixth edition of our LosLosLosheim festivals and we are proud of how much the
                  quality improved over the years. We’ve put a lot of time and effort in organizing them and have
                  always tried to make them affordable for you, but we ended up either losing money or barely
                  breaking even... Therefore, we’ve decided to raise the ticket price a little bit and we hope you can
                  understand. If we manage to make a small profit this edition, we will invest it in the quality of the
                  festival!
                </p>
                <p>
                  - Team Girtenmühle
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon='checkmark'
                  labelPosition='right'
                  content='OK'
                  onClick={() => setShowModalFirstTime(false)}
                />
              </Modal.Actions>
            </Modal>
            <Button
              type='button'
              color='teal'
              fluid
              size='large'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => formState.acceptRules ? setStepNo(stepNo + 1) : setShowModal(true)} />
            <Dimmer active={loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Form >
        )
      case 1:
        return (
          <Form className='attached fluid segment'>
            <p style={{ margin: 0 }}>
              We do everything together, which makes every job a light and fun one to do!
              Don’t forget to let us know if you want to work with a specific friend when
              you are filling out the form.
            </p>
            <p>
              - Volunteers work one shift of 3 hours during the whole festival <br />
              - During your shift you will receive 3 tokens from your captain
            </p>
            <p>
              These are some of the roles that are available:
            </p>
            <p>
              <b>Bar</b><br />
              We have three bars and many people who’d like a beverage!
              We need people that would like to serve drinks and others to restock the fridges.
            </p>
            <p>
              <b>Facility</b><br />
              We live in a beautiful part of Germany, pur Natur. We’d like to keep it neat!
              Empty beer bottles need to be collected and trash bags need a refresh. This job
              enables you to move freely across the terrain to ensure it’s purity.
            </p>
            <p>
              <b>Mood Setting</b><br />
              We are looking for some nice people who want to spread some more love during
              the event, help with organised workshops or activities, set a good mood with a proper bonfire
              or funky movements.
            </p>
            <p>
              <b>Entrance</b><br />
              Some of you are needed to check tickets, hand out the wristbands and sell tokens. You will be one of the first
              friendly faces to welcome all our friends and artists!
            </p>
            <p>
              <b>Parking</b><br />
              You and your team will ensure everyone gets a proper parking spot.
            </p>
            {/* <p>
              <b>Building</b><br />
              Last time, some people built the entrance to our Feuerbühne. This time, we also
              hope creative guests will be inspired to build something majestic for coming events.
            </p> */}
            <br />
            <Form.Select
              label='Preferred Role'
              name='preferredRole'
              value={formState.preferredRole}
              onChange={handleFormChange}
              placeholder='Select your preferred role'
              options={roleOptions} />
            <Form.Select
              label='Backup Role'
              name='backupRole'
              value={formState.backupRole}
              onChange={handleFormChange}
              placeholder='Select your backup role'
              options={roleOptions} />
            <Form.Select
              label='Preferred Shift'
              name='preferredShift'
              value={formState.preferredShift}
              onChange={handleFormChange}
              placeholder='Select your preferred shift'
              options={shiftOptions} />
            <Form.Input
              label='Preferred Buddy'
              name='preferredBuddy'
              value={formState.preferredBuddy}
              onChange={handleFormChange}
              fluid
              //icon='phone'
              //iconPosition='left'
              placeholder='Name of your preferred buddy'
            />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form>)
      case 2:
        return (
          <Form className='attached fluid segment' onSubmit={null}>
            <p style={{ marginTop: 0 }}>
              As we try to make a solid schedule, we would like to know when all of you will arrive.
              You can always update this info in case your planning changes.
            </p>
            <p>
              If you have the time we would like to ask you to come a bit earlier or stay longer
              to help us before and after the festival! We will install lights, artwork and
              build other cool stuff. We hope some creative people will attend so we can make this event look the part too.
              After the festival and resting for a bit, we will be cleaning up and taking everything down to transform it back to a ‘normal’ campsite again.
              We could always use some extra hands! Let us know if you want to help out and please ask your friends!
            </p>
            <Form.Group widths='equal'>
              {/* <DateInput
                label='Arrival Date'
                name='arrivalDate'
                key='arrivalDate'
                placeholder='Arrival Date'
                value={formState.arrivalDate}
                iconPosition='left'
                onChange={handleFormChange}
                closable
                clearable
                clearIcon={<Icon name='remove' color='red' />}
                popupPosition='bottom center'
                animation='none'
              /> */}
              <Form.Select
                label='Arrival Date'
                name='arrivalDate'
                value={formState.arrivalDate}
                onChange={handleFormChange}
                placeholder='Arrival Date'
                options={arrivalDateOptions}
              />
              <Form.Select
                label='Arrival Time'
                name='arrivalTime'
                value={formState.arrivalTime}
                onChange={handleFormChange}
                placeholder='Arrival Time Of Day'
                options={timeOfDayOptions}
              />
            </Form.Group>
            <p>
              We’ve noticed some people kept on chilling on the campsite after the festival.
              Of course, we want you to rest well before heading back! From our end, we need
              to tidy up the mess and open the campsite for regular guests as soon as possible. Therefore, we’ve decided
              everyone is welcome to stay until Monday, on the condition that they leave the campsite latest at 23:00.
            </p>
            <Form.Group widths='equal'>
              {/* <DateInput
                label='Departure Date'
                name='departureDate'
                key='departureDate'
                placeholder='Departure Date'
                value={formState.departureDate}
                iconPosition='left'
                onChange={handleFormChange}
                closable
                clearable
                clearIcon={<Icon name='remove' color='red' />}
                popupPosition='bottom center'
                animation='none'
              /> */}
              <Form.Select
                label='Departure Date'
                name='departureDate'
                value={formState.departureDate}
                onChange={handleFormChange}
                placeholder='Departure Date'
                options={departureDateOptions}
              />
              <Form.Select
                label='Departure Time'
                name='departureTime'
                value={formState.departureTime}
                onChange={handleFormChange}
                placeholder='Departure Time Of Day'
                options={timeOfDayOptions}
              />
            </Form.Group>
            {/* <Form.Group widths='equal'>
              <Form.Checkbox
                label='I can come a bit earlier!'
                name='arriveEarlier'
                key='arriveEarlier'
                checked={formState.arriveEarlier}
                onChange={handleFormChange}
              />
              <Form.Checkbox
                label='I can stay longer!'
                name='departLater'
                key='departLater'
                checked={formState.departLater}
                onChange={handleFormChange}
              />
            </Form.Group> */}
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form >)
      case 3:
        return (
          <Form className='attached fluid segment' onSubmit={null}>
            <p style={{ marginTop: 0 }}>
              Would you like to put in more than the regular 3 hours? Are you motivated to
              take on more responsibilities and lead a team or totally rock a specific job?
              Become a team captain and help us carry the responsibilities to make this festival
              even smoother than the last!
            </p>
            <p>
              - Captains work one shift of 6 hours and lead a team of volunteers<br />
              - If you are selected as a Captain, we will refund your ticket<br />
              - After your shift you will receive 6 tokens<br />
              - Captains will provide their team with information before and during the festival
            </p>
            <Form.Checkbox
              label='I want to play a bigger role!'
              name='biggerRole'
              key='biggerRole'
              checked={formState.biggerRole}
              onChange={handleFormChange}
            />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form>)
      case 4:
        return (
          <Form className='attached fluid segment' onSubmit={null}>
            <Form.TextArea
              label='Would you like to share anything else?'
              placeholder='Tell us more'
              name='remarks'
              value={formState.remarks}
              onChange={handleFormChange} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='arrow right'
              labelPosition='right'
              content='Next'
              onClick={() => setStepNo(stepNo + 1)} />
            <br /><br />
          </Form>)
      case 5:
        return (
          <Form className='attached fluid segment'>
            <p style={{ marginTop: 0 }}>
              You are now joining the LosLosLosheim-CREW. Your team captain will give you a call a bit
              further down the road to provide you with all relevant information about your shift.
              What times of the day would suit you best for a friendly chat?
            </p>
            <Table definition unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {/* <Table.HeaderCell>Before 12:00</Table.HeaderCell> */}
                  <Table.HeaderCell textAlign='center'><Checkbox label='12:00 - 19:00' onChange={(e, d) => { d.checked ? setCallMatrix({ ...callMatrix, mo1: true, tu1: true, we1: true, th1: true, fr1: true, wknd1: true }) : setCallMatrix({ ...callMatrix, mo1: false, tu1: false, we1: false, th1: false, fr1: false, wknd1: false }) }} /></Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'><Checkbox label='After 20:00' onChange={(e, d) => { d.checked ? setCallMatrix({ ...callMatrix, mo2: true, tu2: true, we2: true, th2: true, fr2: true, wknd2: true }) : setCallMatrix({ ...callMatrix, mo2: false, tu2: false, we2: false, th2: false, fr2: false, wknd2: false }) }} /></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Monday</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='mo1'
                      checked={callMatrix.mo1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='mo2'
                      checked={callMatrix.mo2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Tuesday</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='tu1'
                      checked={callMatrix.tu1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='tu2'
                      checked={callMatrix.tu2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Wednesday</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='we1'
                      checked={callMatrix.we1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='we2'
                      checked={callMatrix.we2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Thursday</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='th1'
                      checked={callMatrix.th1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='th2'
                      checked={callMatrix.th2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Friday</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='fr1'
                      checked={callMatrix.fr1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='fr2'
                      checked={callMatrix.fr2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Weekend</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='wknd1'
                      checked={callMatrix.wknd1}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center'>
                    <Checkbox
                      label='Available'
                      name='wknd2'
                      checked={callMatrix.wknd2}
                      onChange={handleCallMatrix}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button
              type='button'
              color='teal'
              size='large'
              floated='left'
              icon='arrow left'
              labelPosition='left'
              content='Previous'
              onClick={() => setStepNo(stepNo - 1)} />
            <Button
              type='button'
              color='teal'
              size='large'
              floated='right'
              icon='check'
              labelPosition='right'
              content='Submit'
              onClick={() => { handleSubmit(); setStepNo(stepNo + 1) }} />
            <br /><br />
          </Form>)
      case 6:
        return (<><Segment attached textAlign='center'>
          <h3>Thanks for filling everything out!</h3>
          <p>
            You can change your information at any time. <br />
            Please make sure your arrival and departure dates are up to date! <br /> <br />
            Your captain will call you in a couple of weeks time, a bit further down the road. <br /> <br />
            See you soon!
          </p>
          {data && data.ticket.length > 0
            ? (<div>
              <br />
              <h3>Congratulations, you have a ticket!</h3>
              <p>
                <Icon name='check' size='massive' color='green' style={{ verticalAlign: 'top', marginRight: '.2em' }} />
                <QRCode value={data.ticket[0].id} size={112} />
              </p>
              <p>
                When the volunteering schedule is finished your ticket will be shown on this page and sent to your email address
              </p>
            </div>)
            : paymentLink
              ? (<>
                <a href={paymentLink}>
                  <Icon name='shopping cart' size='massive' color='teal' style={{ marginTop: '20px' }} />
                </a>
                <h3>Please buy your ticket <a href={paymentLink}>here! </a></h3>
                <p>This ticket includes camping</p>
              </>
              )
              : <Icon loading name='spinner' />}

        </Segment>
          {/* <Button
            type='button'
            color='teal'
            size='large'
            floated='left'
            icon='arrow left'
            labelPosition='left'
            content='Previous'
            onClick={() => setStepNo(stepNo - 1)} />
          <Button
            type='button'
            color='teal'
            size='large'
            floated='right'
            icon='check'
            labelPosition='right'
            content='Submit'
            onClick={() => { setStepNo(stepNo + 1) }} /> */}
        </>)
      // case 6:
      //   return (<Segment attached textAlign='center'>
      //     <h3>Thanks for filling everything out!</h3>
      //     You can change your information at any time, please make sure your arrival
      //     and departure dates are up to date! We will call you as soon as possible, bis bald!
      //   </Segment>)
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 650 }}>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Register for {eventState.name}
          </Header>
          {eventState.date}
        </Message>
        <div style={{ textAlign: 'left' }}>
          {registerSteps(stepNo)}
        </div>
        <Message attached='bottom' info>
          Back to <Link to='/events' onClick={() => setBackground(bg5)}>events</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default EventRegisterPage