import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoginPage from './login/login';
import RegisterPage from './login/register';
import ResetPasswordPage from './login/resetpassword';
import ResetPasswordLinkPage from './login/resetpasswordlink';
import HomePage from './home/home';
import ProfilePage from './user/profile';
import VerifyEmailPage from './login/verifyemail';
import LandingPage from './LandingPage';
import { ToastProvider } from 'react-toast-notifications';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import EventRegisterPage from './event/eventregister';
import { UserProvider } from './userContext';
import EventsPage from './event/events';
import BuyTicketPage from './event/buyTicket';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import ActRegisterPage from './event/actregister';
import ResendVerificationPage from './login/resendverification';

import generatedGitInfo from './generatedGitInfo.json';
import StatsPage from './stats/stats';
import RideShareOverviewPage from './rideshare/overview';
import RideShareRidePage from './rideshare/ride';
import FaqPage from './faq/faq';
import RideSharePostRidePage from './rideshare/postRide';

const httpLink = createHttpLink({
  uri: 'https://hasura.lggm.de/v1/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

ReactGA.initialize('UA-154478362-1');

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  })

  console.log(`
  #######################################
  #      __ __ __             __        #
  #  .--|  |__|__.--------.  |__.-----. #
  #  |  _  |  |  |        |__|  |  _  | #
  #  |_____|  |__|__|__|__|__|__|_____| #
  #       |___|                         #                     
  #                                     #
  #  Code and Design by Djim Molenkamp  #
  #         (find me on djim.io)        #
  #######################################
  `);

  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <ToastProvider>
          <Router history={history}>
            <div>
              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <div style={{ position: 'fixed', bottom: '0px', left: '5px', fontSize: '10px', color: 'lightgray' }}>
                {generatedGitInfo.gitBranch}@{generatedGitInfo.gitCommitHash}
              </div>
              <Switch>
                {/* <Route path='/login' component={LoginPage} />
                <Route path='/register' component={RegisterPage} />
                <Route path='/resendVerification' component={ResendVerificationPage} />
                <Route path='/verifyEmail' component={VerifyEmailPage} />
                <Route path='/resetPassword' component={ResetPasswordPage} />
                <Route path='/resetPasswordLink' component={ResetPasswordLinkPage} />
                <Route path='/stats' component={StatsPage} />
                <PrivateRoute path='/home' component={HomePage} />
                <PrivateRoute path='/profile' component={ProfilePage} />
                <PrivateRoute path='/events' component={EventsPage} />
                <PrivateRoute path='/rideshare/post' component={RideSharePostRidePage} />
                <PrivateRoute path='/rideshare/:rideId' component={RideShareRidePage} />
                <PrivateRoute path='/rideshare' component={RideShareOverviewPage} />
                <PrivateRoute path='/event/:eventId/act' component={ActRegisterPage} />
                <PrivateRoute path='/event/:eventId/ticket' component={BuyTicketPage} />
                <PrivateRoute path='/event/:eventId' component={EventRegisterPage} />
                <Route path='/faq' component={FaqPage} /> */}
                <Route path='/' component={LandingPage} />
              </Switch>
            </div>
          </Router>
        </ToastProvider>
      </UserProvider>
    </ApolloProvider>
  )
}

export default App;