import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Header, Message } from 'semantic-ui-react';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => new URLSearchParams(useLocation().search);

function ResendVerificationPage() {
  const [success, setSuccess] = useState(true)
  let query = useQuery();
  let email = query.get('email');

  useEffect(() => {
    fetch('https://api.lggm.de/resendVerification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setSuccess(data.success);
      })
  },
    // eslint-disable-next-line 
    [])

  return (
    <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Email Verification
          </Header>
        </Message>
        <Message info>
          A new verification mail has {success ? '' : 'NOT'} been sent. Please check your inbox.
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default ResendVerificationPage