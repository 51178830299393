import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Button, Table, Modal, Grid, Header, Input, Message, Icon, Segment } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { UserContext } from '../userContext';

const UPDATE_PHONE_QUERY = gql`
  mutation MyMutation($userId: uuid!, $phone: String!) {
    update_user(where: {id: {_eq: $userId}}, _set: {phone: $phone}) {
      returning {
        phone
      }
    }
  }
`

const ProfilePage = () => {
  const userContext = useContext(UserContext);
  userContext.refetch();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const [updatePhone] = useMutation(UPDATE_PHONE_QUERY);

  const handleFormChange = (e, data) => {
    userContext.setUser({
      ...userContext.user,
      [data.name]: data.value
    })
  }

  const handleUpdatePhone = () => {
    updatePhone({
      variables: {
        userId: userContext.user.id,
        phone: userContext.user.phone
      }
    }).then(res => {
      userContext.refetch();
      setShowModal(false);
      //document.removeEventListener('keyup', handleEnter, false)
    })
  }

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      handleUpdatePhone();
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 650 }}>
        {/* <Step.Group items={steps} attached='bottom' widths={3} />
        <br /> */}
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Profile
          </Header>
          {/* Change your details or sign up for events */}
          <Link as={Link} to={'/home'} title='Back home'>
            <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
              <Icon name='home' />
            </Button>
          </Link>
          <Button
            type='button'
            floated='left'
            color='red'
            onClick={() => { localStorage.removeItem('auth-token'); localStorage.removeItem('email'); history.push('/') }}
            style={{ position: 'absolute', right: 10, top: 11 }}>
            Logout
          </Button>
        </Message>
        <Segment attached>
          {/* <Dimmer active={userContext.loading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer> */}
          <Grid columns={1} stackable divided>
            <Grid.Row>
              <Grid.Column verticalAlign='top' textAlign='left' >
                <Modal size='tiny' open={showModal}>
                  <Modal.Header>Update your phone</Modal.Header>
                  <Modal.Content>
                    <Grid centered columns={2}>
                      <Grid.Column textAlign='right' verticalAlign='middle'>
                        Your new phone number, please include the country code! +31 / +49
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          focus
                          placeholder='Your new phonenumber'
                          name='phone'
                          value={userContext.user.phone}
                          onChange={handleFormChange}
                        />
                      </Grid.Column>
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      positive
                      type="submit"
                      icon='checkmark'
                      labelPosition='right'
                      content='Update'
                      onClick={() => handleUpdatePhone()}
                    />
                  </Modal.Actions>
                  {/* </Form> */}
                </Modal>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Name</Table.Cell>
                      <Table.Cell>{userContext.user.firstName} {userContext.user.lastName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Email</Table.Cell>
                      <Table.Cell>{userContext.user.email}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Phone</Table.Cell>
                      <Table.Cell>{userContext.user.phone}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Button
                  type='button'
                  color='teal'
                  floated='right'
                  content='Update Phone'
                  onClick={() => {
                    setShowModal(true);
                    //document.addEventListener('keyup', handleEnter, false) 
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default ProfilePage