import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Message } from 'semantic-ui-react';

const LandingPage = () => {
  return (
    <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>

        <Message>
          <Header as='h2' color='teal' textAlign='center'>
            LandGut Girtenmühle
          </Header><br />
          {/* Welcome! <br /> */}
          {/* Please <Link to='/login'>login</Link> to access your account.<br />
          Or <Link to='/register'>register here!</Link> */}
          Thanks for an amazing edition ♥️!
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default LandingPage;