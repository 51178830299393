import React, { } from 'react';
import { Button, Grid, Header, Message, Segment, Icon, Table, Dimmer, Loader } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_RIDES = gql`
    query GetRides {
        rideshare {
            id
            userId
            departFrom
            departFromDate
            departFromTime
            spots
            shareMail
            sharePhone
        }
    }  
`;

const RideShareOverviewPage = () => {
    const history = useHistory();

    const { loading, error, data, refetch } = useQuery(GET_RIDES, {
        variables: {
        },
        // fetchPolicy: "network-only"
    });

    if (loading) {
        return (
            <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Message attached style={{ position: 'relative' }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Rideshare
                        </Header>
                    </Message>
                    <Segment attached style={{ height: 100 }}>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                </Grid.Column>
            </Grid >
        )
    }

    return (
        <Grid textAlign='center' style={{ height: '102vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 650 }}>
                <Message attached style={{ position: 'relative' }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Rideshare
                    </Header>
                    <Link as={Link} to={'/home'} title='Back home'>
                        <Button icon type='button' style={{ position: 'absolute', left: 10, top: 11 }}>
                            <Icon name='home' />
                        </Button>
                    </Link>
                    <Link as={Link} to={'/rideshare/post'} title='Post a ride'>
                        <Button icon type='button' style={{ position: 'absolute', right: 10, top: 11 }}>
                            <Icon name='car' /> &nbsp; Post a ride
                        </Button>
                    </Link>
                </Message>
                <Segment attached>
                    <Header as='h3' textAlign='center' floated='left' style={{ marginLeft: '3em' }}>
                        <Icon name='car' /> <Header.Content>{data?.rideshare.length} rides available </Header.Content>
                    </Header>
                    <Header as='h3' textAlign='center' floated='right' style={{ marginRight: '3em' }}>
                        <Icon name='users' /> <Header.Content>10/30 spots filled</Header.Content>
                    </Header>
                    <Table selectable striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>From</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Time</Table.HeaderCell>
                                <Table.HeaderCell>Spots</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data?.rideshare.map(ride =>
                                <Table.Row key={ride.id} onClick={() => history.push(`rideshare/${ride.id}`)} title='Click to open ride'>
                                    <Table.Cell>{ride.departFrom}</Table.Cell>
                                    <Table.Cell>{ride.departFromDate} </Table.Cell>
                                    <Table.Cell>{ride.departFromTime}</Table.Cell>
                                    <Table.Cell>{ride.spots}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default RideShareOverviewPage